import React from "react";
import { NUMSREGEX } from "@constants/regex";
import styled from "@emotion/styled";
import { ClearedStyleInput } from "@modules/Components";

const Input = styled(ClearedStyleInput)`
  width: 100%;
  font-size: 48px !important;
  border-bottom: ${props =>
    props.codeError
      ? "solid 1px rgba(238, 80, 80, 0.48)"
      : "1px solid rgba(31, 32, 33, 0.1)"};
  padding-left: 11px;
  padding-right: 2px;
  &:hover {
    border-bottom: ${props =>
      props.codeError
        ? "solid 1px rgba(238, 80, 80, 0.48)"
        : "1px solid rgba(31, 32, 33, 0.1)"};
  }

  &:focus {
    border-bottom: ${props =>
      props.codeError
        ? "solid 1px rgba(238, 80, 80, 0.48)"
        : "1px solid #ff9c1b"};
  }

  @media (max-width: 375px) {
    font-size: 40px;
  }
`;

const StepperInput = ({
  inputItem,
  name,
  codeError,
  prevSibling,
  nextSibling,
  setInputValue,
  setAllValues,
  allValues,
  handlePaste
}) => {
  const handleChange = e => {
    if (!NUMSREGEX.test(e.target.value.trim()) && e.target.value) {
      return;
    }
    const newVal = e.target.value;
    let valToSet = "";
    if (newVal) {
      valToSet = newVal.substr(newVal.length - 1);
      if (nextSibling && !nextSibling.value) {
        nextSibling.ref.current.focus();
      }
    } else {
      valToSet = newVal;
    }
    setInputValue({ ...inputItem, value: valToSet });
    setAllValues({ ...allValues, [name]: valToSet });
  };

  const handleKeyDown = e => {
    if (e.keyCode === 8) {
      if (prevSibling && !inputItem.value) {
        prevSibling.ref.current.focus();
      }
    } else if (e.keyCode === 37) {
      if (e.target.value && inputItem.ref.current.selectionStart === 1) {
        return;
      } else {
        if (prevSibling) {
          e.preventDefault();
          prevSibling.ref.current.focus();
          prevSibling.ref.current.selectionStart = 1;
          prevSibling.ref.current.selectionEnd = 1;
        }
      }
    } else if (e.keyCode === 39) {
      if (e.target.value && inputItem.ref.current.selectionStart === 0) {
        return;
      } else {
        if (nextSibling) {
          e.preventDefault();
          nextSibling.ref.current.focus();
          nextSibling.ref.current.selectionStart = 0;
          nextSibling.ref.current.selectionEnd = 0;
        }
      }
    }
  };

  return (
    <Input
      ref={inputItem.ref}
      value={inputItem.value}
      name={name}
      codeError={codeError}
      type="text"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      className="stepper-input-item"
    />
  );
};

export default StepperInput;
