import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import StepperInput from "./StepperInput";
import "../styles/stepperInputs.scss";

const MainContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  position: relative;
`;

const MainCodeContent = styled.div`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.gridLength}, 1fr)`};
  grid-gap: 15px;
  margin: 0 auto;
  max-width: ${props => `${props.gridLength * 65}px`};
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(238, 80, 80);
  top: ${({ setTop }) => setTop};
  text-align: center;
  position: absolute;
  width: 100%;
`;

const StepperInputs = ({
  setVerificationCode,
  setValid,
  setCodeError,
  codeError,
  submitHandler,
  submitAttempts,
  valid,
  length,
  displayInputs = true
}) => {
  const [stepperValues, setStepperValues] = useState(
    [...Array(length).keys()].map(key => ({
      value: "",
      ref: useRef(null),
      name: `item#${key}`
    }))
  );

  const [allValues, setAllValues] = useState({});

  useEffect(() => {
    stepperValues[0].ref.current.focus();
  }, []);

  useEffect(() => {
    if (valid && (!submitAttempts || submitAttempts < 2)) {
      submitHandler();
    }
  }, [valid]);

  useEffect(() => {
    const code = Object.keys(allValues)
      .map(item => allValues[item])
      .join("");
    if (code.length === length) {
      setVerificationCode(code);
      setValid(true);
      setCodeError("");
    } else {
      setValid(false);
      setVerificationCode(code);
    }
  }, [allValues]);

  const handlePaste = e => {
    e.preventDefault();
    const data = e.clipboardData.getData("Text");
    if (data) {
      const dataArray = data.split("");
      setStepperValues([
        ...stepperValues.map((item, index) => ({
          ...item,
          value: dataArray[index] || item.value
        }))
      ]);
      const valueObj = {};
      dataArray.forEach((item, index) => {
        valueObj[`item#${index}`] = item || stepperValues[index].value;
      });
      setAllValues({ ...valueObj });
    }
  };

  return (
    <MainContainer className="stepper-input orange-caret remove-number-input-arrows">
      {codeError && (
        <ErrorMessage
          className="stepper-error-message"
          setTop={displayInputs ? "-18px" : "53px"}
        >
          {codeError}
        </ErrorMessage>
      )}
      <MainCodeContent gridLength={length}>
        {displayInputs && (
          <>
            {stepperValues.map((item, index) => (
              <StepperInput
                key={index}
                inputItem={item}
                setInputValue={val => {
                  const newVal = stepperValues;
                  newVal.splice(index, 1, val);
                  setStepperValues(newVal);
                }}
                nextSibling={
                  stepperValues[index + 1] ? stepperValues[index + 1] : null
                }
                prevSibling={
                  stepperValues[index - 1] ? stepperValues[index - 1] : null
                }
                name={item.name}
                codeError={codeError}
                setAllValues={setAllValues}
                allValues={allValues}
                handlePaste={handlePaste}
              />
            ))}
          </>
        )}
      </MainCodeContent>
    </MainContainer>
  );
};

export default StepperInputs;
